import * as React from "react";
import Seo from "../components/seo"
import bg from '../images/dashboard-background.png';
import { Slide, Fade } from 'react-reveal';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import SliderItem from '../components/slider-item';
import Forward from "../components/forward";

var settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  autoplaySpeed: 4000,
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  swipe: false,
};

const IndexPage = () => {
  const [slider, setSlider] = React.useState();
  const [barWidth, setBarwidth] = React.useState(0);
  const [sliderTimer, setSliderTimer] = React.useState();

  const autoplay = () => {
    if (sliderTimer) {
      clearInterval(sliderTimer);
      setSliderTimer(null);
      return null;
    }
    if (slider) {
      const loop = setInterval(() => {
        setBarwidth(prev => {
          if (prev >= 100) {
            slider.slickNext();
            return 0;
          }
          return prev + .5;
        });
      }, 16);
      setSliderTimer(loop);
    }
  };

  React.useEffect(() => {
    autoplay();
    return () => {
      if (sliderTimer) {
        clearInterval(sliderTimer);
      }
    }
  }, [slider])

  return (
    <div class="relative">
      <div class="container mx-auto relative">
        <Seo title="Home" />

        <div class="filter grayscale hidden lg:block absolute right-0 top-0 bottom-0">
          { sliderTimer && (
          <Fade duration={1200}>
            <img src={bg} class="w-full" style={{ maxWidth: 622 }} />
          </Fade> 
          ) }
        </div>

        <div class="hidden lg:block mt-10 lg:mt-0">
          <Slider className="slider-content" ref={ref => (setSlider(ref))} {...settings}>
            <SliderItem slider={slider} index={0}>
              <h2 class="uppercase font-sans text-lg lg:text-xl tracking-wider">
                <div class="overflow-hidden mb-4">
                  <Slide when={slider?.innerSlider.state.currentSlide === 0} bottom duration={1000}>WE ARE HG VENTURES</Slide>
                </div>
                <div class="overflow-hidden mb-4">
                  <Slide when={slider?.innerSlider.state.currentSlide === 0} bottom duration={1000} delay={100}>WE BELIEVE PEOPLE</Slide>
                </div>
                <div class="overflow-hidden mb-4">
                  <Slide when={slider?.innerSlider.state.currentSlide === 0} bottom duration={1000} delay={200}>BUILD COMPANIES.</Slide>
                </div>
              </h2>
            </SliderItem>
            <SliderItem slider={slider} index={1}>
              <h2 class="uppercase font-sans text-lg lg:text-xl tracking-wider">
                <div class="overflow-hidden mb-4">
                  <Slide when={slider?.innerSlider.state.currentSlide === 1} bottom duration={1000}>WE IDENTIFY THOSE</Slide>
                </div>
                <div class="overflow-hidden mb-4">
                  <Slide when={slider?.innerSlider.state.currentSlide === 1} bottom duration={1000} delay={100}>UNCOMMON PEOPLE WHO</Slide>
                </div>
                <div class="overflow-hidden mb-4">
                  <Slide when={slider?.innerSlider.state.currentSlide === 1} bottom duration={1000} delay={200}>HAVE WHAT IT TAKES</Slide>
                </div>
                <div class="overflow-hidden">
                  <Slide when={slider?.innerSlider.state.currentSlide === 1} bottom duration={1000} delay={300}>TO MAKE THAT HAPPEN.</Slide>
                </div>
              </h2>
            </SliderItem>
          </Slider>
        </div>
        <div>
          <div class="lg:hidden py-10">
            <h1 class="uppercase font-sans text-lg lg:text-xl tracking-wider mb-10">
              <Fade>
                WE ARE HG VENTURES. WE BELIEVE PEOPLE BUILD COMPANIES.
              </Fade>
            </h1>
            <h1 class="uppercase font-sans text-lg lg:text-xl tracking-wider">
              <Fade>
                WE IDENTIFY THOSE UNCOMMON PEOPLE WHO HAVE WHAT IT TAKES TO MAKE THAT HAPPEN.
              </Fade>
            </h1>
          </div>
        </div>
        <div class="hidden lg:block w-full bottom-0 right-0 absolute" style={{ maxWidth: 622 }}>
          <Slide bottom duration={500}>
            <div style={{ height: 22, backgroundColor: '#fb4b06' }}>
              <div style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, .2)', width: `${barWidth}%` }}></div>
            </div>
          </Slide>
        </div>
      </div>
      <div class="lg:hidden">
        <Forward to="/people/" />
      </div>
    </div>
  )
};

export default IndexPage
