import React from 'react';

const SliderItem = ({ index, children, src, slider }) => {
	return (
		<div class="flex items-center relative slider-content">
			<div class="p-10 lg:p-0 lg:pl-4 xl:pl-2 2xl:pl-0 relative z-100">
				{children}
			</div>
		</div>
	);
};

export default SliderItem;
